import { defineStore } from "pinia";
import { doFetchUserSummary } from "../services/gate.service";
import { getAuthUser } from "../common/auth";

export const useAuthStore = defineStore({
  id: "Auth",

  state: () => ({
    userAuth: getAuthUser(),
    userDetails: null,
  }),
  getters: {
    getUserAuth(state) {
      return state.userAuth;
    },
    getUserDetails(state) {
      return state.userDetails;
    },
  },
  actions: {
    // Fetch Blog from action
    async fetchUserSummary({ userId }: { userId: string }) {
      try {
        const resp = await doFetchUserSummary({ userId });
        this.userAuth = resp ? resp.user_profile : null;
        this.userDetails = resp ? resp : null;
        return resp;
      } catch (error) {
        //  alert(error);
        console.log(error);
      }
    },
  },
});
