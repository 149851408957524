<script setup lang="ts">
import { ref, onBeforeMount } from "vue";
import { isTokenExpired, getAuthUser } from "@/common/auth";
import BlueWhiteLogo from "@/layouts/logo/BlueWhiteLogo.vue";
import { Menu2Icon } from "vue-tabler-icons";
import Login from "@/layouts/shared/auth/Login.vue";
import Register from "@/layouts/shared/auth/Register.vue";
// import { menu } from "@/_mockApis/header/Menu";
import NavGroup from "./NavGroup/index.vue";
import NavItem from "./NavItem/index.vue";
import NavCollapse from "./NavCollapse/NavCollapse.vue";
import MobileNavCollapse from "./NavCollapse/MobileNavCollapse.vue";
import { Base64 } from "js-base64";
import defaultAvatar from "@/components/assets/images/dufaultAvatar.png";
import { useAuthStore } from "@/store/auth";
//const runtimeConfig = useRuntimeConfig();

const signInStore: any = useAuthStore();
let loginUserAuth = getAuthUser(); //signInStore.getUserAuth;
let loginUserProfile = signInStore.getUserDetails;

const userTeamRoles = ref([]);

import { useRoute } from "vue-router";
const route = useRoute();

const drawer = ref(false);
let loginUser = ref("");
let userProfile = ref("");

// let expiredToken = isTokenExpired(
//   runtimeConfig.public.NUXT_MY_APP_PINE_HUB_NAME
// );

let expiredToken = isTokenExpired("pinehub");

const sidebarItem: Array<any> = [
  {
    title: "Home",
    to: "/",
  },
  {
    title: "About",
    to: "/about-us",
  },

  {
    title: "Jobs",
    to: "/jobs",
  },
  // do not change the Blog index in this array
  {
    title: "Blog",
    to: "/blog",
    children: [],
  },

  // {
  //   title: "Founders",
  //   to: "/founders",
  // },
  // {
  //   title: "Investors",
  //   to: "/investors",
  // },
  // {
  //   title: "Workers",
  //   to: "/workers",
  // },
];

//For on Scroll Effect on Header

const stickyHeader = ref(false);
function handleScroll() {
  if (window.pageYOffset) {
    stickyHeader.value = true;
  } else {
    stickyHeader.value = false;
  }
}

// const getAuthUser = function () {
//   const userAuthEncoded = cookies.get("pineauth");
//   if (userAuthEncoded === null) {
//     loginUser.value = "";
//     return "";
//   }

//   const userAuth = JSON.parse(Base64.decode(userAuthEncoded));
//   loginUser.value = userAuth;
//   return userAuth;
// };
//For on Scroll Effect on Header
onMounted(async () => {
  window.addEventListener("scroll", handleScroll);
  //  let userAuthResp = getAuthUser();
  if (loginUserAuth && !expiredToken) {
    loginUser.value = loginUserAuth;
    userProfile.value = loginUserProfile;
  }
  // let resp = await signInStore.fetchUserSummary({
  //   userId: loginUserAuth.id,
  // });

  // if (resp) {
  //   if (resp.team_roles && resp.team_roles.length) {
  //     userTeamRoles.value = resp.team_roles.map((item: any) => {
  //       return item.team_role;
  //     });
  //   }

  if (loginUserAuth && !expiredToken) {
    sidebarItem[3].children.push({
      title: "Add Article",
      to: "/blog/add",
    });
  }

  if (!expiredToken && route.params.slug && loginUserAuth) {
    sidebarItem[3].children.push(
      {
        title: "Define Article",
        to: `/blog/define/${route.params.slug}`,
      },
      {
        title: "Edit Article",
        to: `/blog/edit/${route.params.slug}`,
      }
    );
  }
  // }
  // }
});
</script>
<template>
  <div class="header it-header mt-n95">
    <!----sidebar menu drawer start----->
    <ClientOnly>
    <v-navigation-drawer
      color="white"
      class="drawer"
      v-model="drawer"
      temporary
    >
      <perfect-scrollbar class="scrollnavbar">
        <!-- <MobileNavigation /> -->
        <v-list class="menu text-capitalizew-100">
          <!---Menu Loop -->
          <template v-for="(item, i) in sidebarItem" :key="item.title">
            <!---Item Sub Header -->
            <!-- <NavGroup :item="item" v-if="item.header" /> -->
            <!---If Has Child -->
            <MobileNavCollapse
              class=""
              :item="item"
              v-if="item.children && item.children.length"
            />
            <!---Single Item-->
            <NavItem :item="item" v-else class="" />
            <!---End Single Item-->
          </template>
        </v-list>
      </perfect-scrollbar>
    </v-navigation-drawer>
    </ClientOnly>
    <!----sidebar menu drawer end----->
    <v-app-bar
      flat
      class="header-card py-4"
      :class="stickyHeader ? 'sticky' : ''"
    >
      <!-- <v-container class="py-0"> -->
      <v-toolbar class="h-auto d-flex">
        <BlueWhiteLogo />
        <v-list
          class="d-md-flex d-none flex-wrap py-0 menu justify-center w-100"
        >
          <!---Menu Loop -->
          <template v-for="(item, i) in sidebarItem" :key="item.title">
            <!---Item Sub Header -->
            <!-- <NavGroup
              :item="item"
              v-if="
                [item.header, 'Founders', 'Investors', 'Workers'].includes(
                  item.title
                )
              "
            /> -->
            <!---If Has Child -->
            <NavCollapse
              class=""
              :item="item"
              v-if="item.children && item.children.length"
              :level="0"
            />
            <!---Single Item-->
            <NavItem :item="item" v-else class="" />
            <!---End Single Item-->
          </template>
        </v-list>
        <div class="d-md-flex d-none mr-16" v-if="loginUser && loginUser.email">
          <h4 class="text-h4 text-dark font-weight-bold mr-2 mt-1">
            {{ loginUser.first_name }}&nbsp;{{ loginUser.last_name }}
          </h4>
          <img
            class="ma-auto"
            width="40"
            :src="
              userProfile &&
              userProfile.avatar_url &&
              userProfile.avatar_url !== ''
                ? userProfile.avatar_url
                : defaultAvatar
            "
            cover
            :aspect-ratio="4 / 3"
          />
        </div>
        <div class="d-md-flex d-none mr-16" v-else>
          <Login />
          <!-----Login Button and Popup----->

          <!-----Register Button and Popup  ----->
          <Register to="/registration" />
        </div>

        <Menu2Icon
          class="d-md-none d-flex drawer-icon drawer-icon no-effect ml-auto mr-5 text-surface"
          @click.stop="drawer = !drawer"
          size="30"
        ></Menu2Icon>
      </v-toolbar>
      <!-- </v-container> -->
    </v-app-bar>
  </div>
</template>
